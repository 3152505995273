<template>
  <v-row class="center">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <div class="entry-content clearfix">
            <div id="pl-182" class="panel-layout">
              <div id="pg-182-0" class="panel-grid panel-no-style">
                <div id="pgc-182-0-0" class="panel-grid-cell">
                  <div
                    id="panel-182-0-0-0"
                    class="so-panel widget widget_sow-editor panel-first-child panel-last-child"
                    data-index="0"
                  >
                    <div class="so-widget-sow-editor so-widget-sow-editor-base">
                      <div class="siteorigin-widget-tinymce textwidget">
                        <h4>
                          <span style="font-size: 12pt"
                            ><strong
                              ><span
                                style="
                                  font-family: arial, helvetica, sans-serif;
                                  color: #8e2b55;
                                "
                                >Rupali Investment Limited</span
                              ></strong
                            ></span
                          >
                        </h4>
                        <p style="text-align: left">
                          Shadharan Bima Tower(7th Floor)<br />
                          37/A, Dilkusha C/A<br />
                          Dhaka-1000<br />
                          Phone:47112923<br />
                          Fax: 88-02-47118073<br />
                          <a
                            href="mailto:E-mail%3Ainfo@riltd.org"
                            target="_blank"
                            >E-mail: info@riltd.org</a
                          >
                        </p>
                        <p>
                          <span
                            style="
                              font-family: arial, helvetica, sans-serif;
                              font-size: 10pt;
                            "
                            >&nbsp;</span
                          >
                        </p>
                        <p>
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.594877365002!2d90.41765741492554!3d23.726157084601255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b85063875273%3A0x7b94a628797b4886!2sSadharan+Bima+Corporation+Tower%2C+Dilkusha+Rd%2C+Dhaka+1000!5e0!3m2!1sen!2sbd!4v1471178445623"
                            width="932"
                            height="334"
                            frameborder="0"
                            allowfullscreen="allowfullscreen"
                          ></iframe>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style></style>
