<template>
  <v-container>
    <v-card class="full-width-card elevation-3 mb-5">
      <v-card-title class="headline primary--text"
        >Corporate profile</v-card-title
      >
      <v-divider></v-divider>
      <v-row>
        <v-col v-for="label in labels" :key="label.id" cols="12" sm="6" md="4">
          <v-card :class="['label-card', randomColor()]">
            <v-card-title class="label-title">{{ label.label }}</v-card-title>
            <v-card-subtitle class="label-value">{{
              label.value
            }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "../firebase";

export default {
  data() {
    return {
      labels: [],
      colors: [
        "red lighten-2",
        "blue lighten-2",
        "green lighten-2",
        "orange lighten-2",
        "purple lighten-2",
      ],
    };
  },
  async created() {
    const snapshot = await db.collection("labels").get();
    this.labels = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    this.labels.sort((a, b) => b.priority - a.priority);
  },
  methods: {
    randomColor() {
      const index = Math.floor(Math.random() * this.colors.length);
      return this.colors[index];
    },
  },
};
</script>

<style>
.full-width-card {
  max-width: none;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}
.primary--text {
  color: #3f51b5 !important;
}
.label-card {
  height: 200px; /* Ensures all cards are of the same size */
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Adds padding inside the cards */
  text-align: center; /* Centers text horizontally */
}
.label-title {
  font-size: 1.5rem; /* Larger font size for the label */
  font-weight: bold; /* Makes the label text bold */
}
.label-value {
  font-size: 1.25rem; /* Larger font size for the value */
  margin-top: 10px; /* Adds spacing between the label and value */
}
</style>
