<template>
  <v-container class="recommendHomeVue">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card class="upload-card">
          <v-card-title class="notice-card-title">
            <span class="notice-title">IPO Lottery Result</span>
          </v-card-title>
          <v-card-text>
            <v-file-input
              v-model="selectedFile"
              label="Upload PDF"
              accept=".pdf"
              class="upload-input"
            ></v-file-input>

            <v-text-field
              v-model="noticeName"
              label="Name"
              class="name-field"
            ></v-text-field>

            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="noticeDate"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="date-field"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="noticeDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>

            <v-btn @click="uploadNotice" color="primary" class="upload-btn">
              Upload
            </v-btn>

            <v-progress-linear
              v-if="uploading"
              :value="uploadProgress"
              class="progress-bar"
              height="10"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12">
        <v-card class="notice-card">
          <v-card-title class="notice-card-title">
            <span class="notice-title">IPO Lottery Result</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="search-field"
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="notices"
            :search="search"
            class="mytable"
            dense
          >
            <template v-slot:item.Name="{ item }">
              <v-avatar size="50" tile class="avatar">
                <v-img src="@/assets/pdficon.png"></v-img>
              </v-avatar>
              <span class="notice-name">{{ item.Name }}</span>
            </template>
            <template v-slot:item.download="{ item }">
              <v-btn
                x-small
                color="primary"
                @click="viewPdf(item.downloadUrl)"
                class="view-pdf-btn"
              >
                View PDF
              </v-btn>
              <v-btn
                x-small
                color="primary"
                @click="downloadPdf(item.downloadUrl)"
                class="download-pdf-btn"
              >
                Download PDF
              </v-btn>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-btn
                x-small
                color="red"
                @click="confirmDelete(item)"
                class="delete-btn"
              >
                Delete
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, getDownloadURL } from "firebase/storage";
import { db, auth, storage } from "@/firebase";

export default {
  data() {
    return {
      NoticeData: "",
      search: "",
      headers: [
        { text: "Title", value: "Name" },
        { text: "Date", value: "Date", sortable: true, align: "start" },
        { text: "Download", value: "download" },
        { text: "Delete", value: "delete" },
      ],
      notices: [],
      selectedFile: null,
      downloadURL: "",
      noticeName: "",
      noticeDate: "",
      menu: false,
      storageRef: storage.ref(),
      uploading: false,
      uploadProgress: 0,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadNotice() {
      if (!this.selectedFile) {
        alert("Please select a file.");
        return;
      }

      if (!this.noticeName || !this.noticeDate) {
        alert("Please enter name and date for the notice.");
        return;
      }

      this.uploading = true;
      this.uploadProgress = 0;

      try {
        const storageRef = this.storageRef.child(
          `uploads/${this.selectedFile.name}`
        );
        const uploadTask = storageRef.put(this.selectedFile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.uploadProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error("Error uploading notice:", error);
            this.uploading = false;
          },
          async () => {
            this.downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            const user = auth.currentUser;
            if (user) {
              this.NoticeData = {
                Title: this.selectedFile.name,
                Name: this.noticeName,
                Date: this.noticeDate,
                downloadUrl: this.downloadURL,
                userId: user.uid,
              };

              await db.collection("IpoLotteryresultAdd").add(this.NoticeData);
            }

            this.fetchNotices();
            this.resetForm();
            this.uploading = false;
            alert("File uploaded successfully!");
          }
        );
      } catch (error) {
        console.error("Error uploading notice:", error);
        this.uploading = false;
      }
    },
    resetForm() {
      this.selectedFile = null;
      this.noticeName = "";
      this.noticeDate = "";
    },
    fetchNotices() {
      db.collection("IpoLotteryresultAdd")
        .get()
        .then((querySnapshot) => {
          this.notices = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          this.notices.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        });
    },
    viewPdf(downloadUrl) {
      getDownloadURL(ref(storage, downloadUrl))
        .then((url) => {
          window.open(url, "_blank");
        })
        .catch((error) => {
          console.error("Error fetching PDF URL: ", error);
        });
    },
    downloadPdf(downloadUrl) {
      getDownloadURL(ref(storage, downloadUrl))
        .then((url) => {
          const a = document.createElement("a");
          a.href = url;
          a.download = "your-pdf-file.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.error("Error fetching PDF URL: ", error);
        });
    },
    confirmDelete(notice) {
      if (confirm("Are you sure you want to delete this notice?")) {
        this.deleteNotice(notice);
      }
    },
    async deleteNotice(notice) {
      try {
        const storageRef = storage.refFromURL(notice.downloadUrl);
        await storageRef.delete();
        await db.collection("IpoLotteryresultAdd").doc(notice.id).delete();
        this.fetchNotices();
      } catch (error) {
        console.error("Error deleting notice:", error);
      }
    },
  },
  created() {
    this.fetchNotices();
  },
};
</script>
<style scoped>
.recommendHomeVue {
  padding: 20px;
}

.upload-card,
.notice-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.notice-card-title {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.notice-title {
  font-weight: bold;
  font-size: 1.2em;
}

.upload-input,
.name-field,
.date-field {
  margin-bottom: 20px;
}

.upload-btn {
  width: 100%;
  margin-top: 20px;
}

.v-file-input__slot {
  cursor: pointer;
  border: 2px dashed #cccccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.v-menu__content {
  z-index: 9999;
}

.progress-bar {
  margin-top: 20px;
}

.notice-name {
  margin-left: 10px;
  font-weight: bold;
}

.view-pdf-btn,
.download-pdf-btn,
.delete-btn {
  margin-right: 5px;
}
</style>
