<template>
  <v-container class="recommendHomeVue" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="upload-card pa-4">
          <v-card-title class="image-card-title">
            <v-icon color="primary" class="mr-2">mdi-image-plus</v-icon>
            <span class="image-title">Add Image</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-file-input
              v-model="selectedFile"
              label="Upload Image"
              accept="image/*"
              class="upload-input"
              prepend-icon="mdi-upload"
              outlined
              dense
            ></v-file-input>

            <v-text-field
              v-model="imageName"
              label="Name"
              class="name-field mt-4"
              outlined
              dense
            ></v-text-field>

            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="imageDate"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="date-field mt-4"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="imageDate"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>

            <v-text-field
              v-model="imagePriority"
              label="Priority"
              type="number"
              class="priority-field mt-4"
              outlined
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="uploadImage"
              color="primary"
              class="upload-btn"
              :disabled="uploading"
            >
              Upload
            </v-btn>
          </v-card-actions>
          <v-progress-linear
            v-if="uploading"
            :value="uploadProgress"
            class="progress-bar"
            height="10"
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-5">
      <v-col cols="12" md="8">
        <v-card class="image-card pa-4">
          <v-card-title class="image-card-title">
            <v-icon color="primary" class="mr-2">mdi-image-multiple</v-icon>
            <span class="image-title">Images</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="search-field"
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="images"
              :search="search"
              class="mytable"
              dense
              hide-default-footer
            >
              <template v-slot:item.Name="{ item }">
                <v-avatar size="50" tile class="avatar">
                  <v-img :src="item.downloadUrl"></v-img>
                </v-avatar>
                <a>{{ item.Name }}</a>
              </template>
              <template v-slot:item.download="{ item }">
                <v-btn
                  x-small
                  color="primary"
                  @click="viewImage(item.downloadUrl)"
                  class="view-image-btn"
                  outlined
                >
                  View
                </v-btn>
                <v-btn
                  x-small
                  color="primary"
                  @click="downloadImage(item.downloadUrl)"
                  class="download-image-btn"
                  outlined
                >
                  Download
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn
                  x-small
                  color="red"
                  @click="confirmDelete(item)"
                  class="delete-btn"
                  outlined
                >
                  Delete
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db, auth, storage } from "@/firebase";

export default {
  data() {
    return {
      ImageData: "",
      search: "",
      headers: [
        { text: "Title", value: "Name" },
        { text: "Date", value: "Date", sortable: true, align: "start" },
        { text: "Priority", value: "Priority", sortable: true, align: "start" },
        { text: "Download", value: "download" },
        { text: "Delete", value: "delete" },
      ],
      images: [],
      selectedFile: null,
      downloadURL: "",
      imageName: "",
      imageDate: "",
      imagePriority: 0,
      menu: false,
      storageRef: storage.ref(),
      uploading: false,
      uploadProgress: 0,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadImage() {
      if (!this.selectedFile) {
        alert("Please select an image.");
        return;
      }

      if (!this.imageName || !this.imageDate) {
        alert("Please enter name and date for the image.");
        return;
      }

      this.uploading = true;
      this.uploadProgress = 0;

      try {
        const storageRef = this.storageRef.child(
          `Gallery/${this.selectedFile.name}`
        );
        const uploadTask = storageRef.put(this.selectedFile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.uploadProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error("Error uploading image:", error);
            this.uploading = false;
          },
          async () => {
            this.downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            const user = auth.currentUser;
            if (user) {
              this.ImageData = {
                Title: this.selectedFile.name,
                Name: this.imageName,
                Date: this.imageDate,
                Priority: this.imagePriority,
                downloadUrl: this.downloadURL,
                userId: user.uid,
              };

              await db.collection("Gallery").add(this.ImageData);
            }

            this.fetchImages();
            this.resetForm();
            this.uploading = false;
            alert("Image uploaded successfully!");
          }
        );
      } catch (error) {
        console.error("Error uploading image:", error);
        this.uploading = false;
      }
    },
    resetForm() {
      this.selectedFile = null;
      this.imageName = "";
      this.imageDate = "";
      this.imagePriority = 0;
    },
    fetchImages() {
      db.collection("Gallery")
        .get()
        .then((querySnapshot) => {
          this.images = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          this.images.sort((a, b) => b.Priority - a.Priority);
        });
    },
    viewImage(downloadUrl) {
      window.open(downloadUrl, "_blank");
    },
    downloadImage(downloadUrl) {
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "your-image-file.jpg"; // or extract the file name from the URL
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    confirmDelete(image) {
      if (confirm("Are you sure you want to delete this image?")) {
        this.deleteImage(image);
      }
    },
    async deleteImage(image) {
      try {
        const storageRef = storage.refFromURL(image.downloadUrl);
        await storageRef.delete();
        await db.collection("Images").doc(image.id).delete();
        this.fetchImages();
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    },
  },
  created() {
    this.fetchImages();
  },
};
</script>
<style scoped>
.recommendHomeVue {
  background-color: #f5f5f5;
  padding-top: 20px;
}

.upload-card {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.image-card-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #3f51b5;
}

.image-title {
  font-size: 18px;
}

.image-card {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search-field {
  max-width: 300px;
}

.upload-input {
  width: 100%;
}

.name-field,
.date-field,
.priority-field {
  width: 100%;
}

.avatar {
  margin-right: 10px;
}

.upload-btn,
.view-image-btn,
.download-image-btn,
.delete-btn {
  margin: 5px 0;
}

.mytable {
  margin-top: 10px;
}
</style>
