<template>
  <v-container class="recommendHomeVue" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="upload-card pa-4">
          <v-card-title class="form-card-title">
            <v-icon color="primary" class="mr-2">mdi-account-plus</v-icon>
            <span class="form-title">Add Audit Committe</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-file-input
              v-model="selectedFile"
              label="Upload Image"
              accept="image/*"
              class="upload-input"
              prepend-icon="mdi-upload"
              outlined
              dense
            ></v-file-input>

            <v-text-field
              v-model="userName"
              label="Name"
              class="name-field mt-4"
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model="userDesignation"
              label="Designation"
              class="designation-field mt-4"
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model="userEmail"
              label="Email"
              type="email"
              class="email-field mt-4"
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model="userPriority"
              label="Priority"
              type="number"
              class="priority-field mt-4"
              outlined
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="uploadUser"
              color="primary"
              class="upload-btn"
              :disabled="uploading"
            >
              Upload
            </v-btn>
          </v-card-actions>
          <v-progress-linear
            v-if="uploading"
            :value="uploadProgress"
            class="progress-bar"
            height="10"
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-5">
      <v-col cols="12" md="8">
        <v-card class="user-card pa-4">
          <v-card-title class="user-card-title">
            <v-icon color="primary" class="mr-2">mdi-account-multiple</v-icon>
            <span class="user-title">Users</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="search-field"
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="users"
              :search="search"
              class="mytable"
              dense
              hide-default-footer
            >
              <template v-slot:item.Name="{ item }">
                <v-avatar size="50" tile class="avatar">
                  <v-img :src="item.downloadUrl"></v-img>
                </v-avatar>
                <a>{{ item.Name }}</a>
              </template>
              <template v-slot:item.download="{ item }">
                <v-btn
                  x-small
                  color="primary"
                  @click="viewImage(item.downloadUrl)"
                  class="view-image-btn"
                  outlined
                >
                  View
                </v-btn>
                <v-btn
                  x-small
                  color="primary"
                  @click="downloadImage(item.downloadUrl)"
                  class="download-image-btn"
                  outlined
                >
                  Download
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn
                  x-small
                  color="red"
                  @click="confirmDelete(item)"
                  class="delete-btn"
                  outlined
                >
                  Delete
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db, auth, storage } from "@/firebase";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "Name" },
        { text: "Designation", value: "Designation" },
        { text: "Email", value: "Email" },
        { text: "Priority", value: "Priority" },
        { text: "Download", value: "download" },
        { text: "Delete", value: "delete" },
      ],
      users: [],
      selectedFile: null,
      downloadURL: "",
      userName: "",
      storageRef: storage.ref(),
      userDesignation: "",
      userEmail: "",
      userPriority: 0,
      uploading: false,
      uploadProgress: 0,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadUser() {
      if (!this.selectedFile) {
        alert("Please select an image.");
        return;
      }

      if (!this.userName || !this.userDesignation || !this.userEmail) {
        alert("Please enter all user details.");
        return;
      }

      this.uploading = true;
      this.uploadProgress = 0;

      try {
        const storageRef = this.storageRef.child(
          `AuditCommitte/${this.selectedFile.name}`
        );
        const uploadTask = storageRef.put(this.selectedFile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.uploadProgress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error("Error uploading image:", error);
            this.uploading = false;
          },
          async () => {
            this.downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            const user = auth.currentUser;
            if (user) {
              const userData = {
                Name: this.userName,
                Designation: this.userDesignation,
                Email: this.userEmail,
                Priority: this.userPriority,
                downloadUrl: this.downloadURL,
                userId: user.uid,
              };

              await db.collection("AuditCommitte").add(userData);
            }

            this.fetchUsers();
            this.resetForm();
            this.uploading = false;
            alert("User uploaded successfully!");
          }
        );
      } catch (error) {
        console.error("Error uploading user:", error);
        this.uploading = false;
      }
    },
    resetForm() {
      this.selectedFile = null;
      this.userName = "";
      this.userDesignation = "";
      this.userEmail = "";
      this.userPriority = 0;
    },
    fetchUsers() {
      db.collection("AuditCommitte")
        .orderBy("Priority", "desc")
        .get()
        .then((querySnapshot) => {
          this.users = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          this.users.sort((a, b) => b.Priority - a.Priority);
        });
    },
    viewImage(downloadUrl) {
      window.open(downloadUrl, "_blank");
    },
    downloadImage(downloadUrl) {
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "user-image-file.jpg"; // or extract the file name from the URL
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    confirmDelete(user) {
      if (confirm("Are you sure you want to delete this user?")) {
        this.deleteUser(user);
      }
    },
    async deleteUser(user) {
      try {
        const storageRef = storage.refFromURL(user.downloadUrl);
        await storageRef.delete();
        await db.collection("AuditCommitte").doc(user.id).delete();
        this.fetchUsers();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
.recommendHomeVue {
  background-color: #f5f5f5;
  padding-top: 20px;
}

.upload-card {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-card-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #3f51b5;
}

.form-title {
  font-size: 18px;
}

.user-card {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search-field {
  max-width: 300px;
}

.upload-input {
  width: 100%;
}

.name-field,
.designation-field,
.email-field,
.priority-field {
  width: 100%;
}

.avatar {
  margin-right: 10px;
}

.upload-btn,
.view-image-btn,
.download-image-btn,
.delete-btn {
  margin: 5px 0;
}

.mytable {
  margin-top: 10px;
}
</style>
