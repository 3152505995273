<!-- src/views/Dashboard.vue -->
<template>
  <v-container>
    <v-btn @click="logout" color="primary">Logout</v-btn>
    <v-form @submit.prevent="addLabel">
      <v-text-field
        v-model="newLabel"
        label="New Label"
        required
      ></v-text-field>
      <v-text-field v-model="newValue" label="Value" required></v-text-field>
      <v-text-field
        v-model="newPriority"
        label="Priority"
        required
      ></v-text-field>
      <v-text-field
        v-model="newDesc"
        label="Description"
        required
      ></v-text-field>
      <v-btn type="submit" color="primary">Add Label</v-btn>
    </v-form>
    <v-data-table
      :headers="headers"
      :items="labels"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small @click="editItem(item.id)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item.id)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { auth, db } from "../firebase";

export default {
  data() {
    return {
      headers: [
        { text: "Label", value: "label" },
        { text: "Value", value: "value" },
        { text: "Priority", value: "priority" },
        { text: "Description", value: "desc" },
        { text: "Action", value: "action", sortable: false },
      ],
      newLabel: "",
      newValue: "",
      newPriority: "",
      newDesc: "",
      labels: [],
    };
  },
  async created() {
    const user = auth.currentUser;
    if (user) {
      const snapshot = await db
        .collection("labels")
        .where("userId", "==", user.uid)
        .get();
      this.labels = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      this.labels.sort((a, b) => b.priority - a.priority);
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    async addLabel() {
      const user = auth.currentUser;
      if (
        user &&
        this.newLabel &&
        this.newValue &&
        this.newPriority &&
        this.newDesc
      ) {
        const label = {
          label: this.newLabel,
          value: this.newValue,
          priority: this.newPriority,
          desc: this.newDesc,
          userId: user.uid,
        };
        const docRef = await db.collection("labels").add(label);
        this.labels.push({ id: docRef.id, ...label });
        this.newLabel = "";
        this.newValue = "";
        this.newPriority = "";
        this.newDesc = "";
      }
    },
    async deleteLabel(labelId) {
      await db.collection("labels").doc(labelId).delete();
      this.labels = this.labels.filter((label) => label.id !== labelId);
    },
    editItem(itemId) {
      const label = this.labels.find((label) => label.id === itemId);
      if (label) {
        const newLabel = prompt("Edit label:", label.label);
        const newValue = prompt("Edit value:", label.value);
        const newPriority = prompt("Edit priority:", label.priority);
        const newDesc = prompt("Edit description:", label.desc);
        if (
          newLabel !== null &&
          newValue !== null &&
          newPriority !== null &&
          newDesc !== null
        ) {
          db.collection("labels").doc(itemId).update({
            label: newLabel,
            value: newValue,
            priority: newPriority,
            desc: newDesc,
          });
          label.label = newLabel;
          label.value = newValue;
          label.priority = newPriority;
          label.desc = newDesc;
        }
      }
    },
    async deleteItem(labelId) {
      await db.collection("labels").doc(labelId).delete();
      this.labels = this.labels.filter((label) => label.id !== labelId);
    },
    async logout() {
      await auth.signOut();
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.elevation-1 {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
}
</style>
