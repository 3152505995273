<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="entry-content clearfix">
          <p>
            <span style="color: #8e2b55"
              ><strong
                ><span style="font-family: 'times new roman', times"
                  >Required Document to open an Investment Account:</span
                ></strong
              ></span
            >
          </p>
          <ul>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >2 (two) copies of PP size photographs of applicant (with name
                written on the other side) attested by the introducer.</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >1 (one) copy of PP size photograph of Nominee of the
                account.</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >Bank Certificate / Bank account transaction statement.</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >Applicant valid Passport copy / National ID card / Birth
                Certificate / Nationality Certificate.</span
              >
            </li>
          </ul>
          <p>
            <span style="color: #8e2b55"
              ><strong
                ><span style="font-family: 'times new roman', times"
                  >Important Guideline:</span
                ></strong
              ></span
            >
          </p>
          <ul>
            <p></p>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >The introducer needs to have an investment account in RIL or
                officer of Rupali &nbsp;Bank having PA can introduce.</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >Persons of unsound mind or minors are not eligible to open an
                investment account.</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >Maximum Two accounts can be opened One in the name of the
                individuals and other jointly.</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >The account holder required to physically present while opening
                investment account.</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >Initial deposit for opening an Investment account is Tk. 2
                (Two) lac.</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >Documentation charge, Account opening form and stamp duty
                comprises Tk. 1000 (One Thousand).</span
              >
            </li>
            <li>
              <span
                style="font-family: 'times new roman', times; font-size: 12pt"
                >Corporate account can be opened in RIL with the submission of
                relevant documents</span
              >
            </li>
          </ul>
          <p>
            <span style="font-family: 'times new roman', times; color: #800080"
              ><strong
                >&nbsp;<span style="color: #8e2b55"
                  >Interest, Fees and Commission:&nbsp;</span
                ></strong
              ></span
            >
          </p>
          <ul>
            <li>
              <span
                style="font-family: 'times new roman', times; color: #800080"
                ><strong
                  ><span style="color: #8e2b55"
                    >Portfolio Management Charge:</span
                  >
                </strong></span
              >
            </li>
          </ul>
          <p>
            <span style="font-family: 'times new roman', times; font-size: 12pt"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Total portfolio value per annum with a minimum charge of TK
              500.00</span
            >
          </p>
          <ul>
            <li>
              <span
                style="font-family: 'times new roman', times; color: #800080"
                ><strong
                  ><span style="color: #8e2b55">Interest on Margin Loan :</span>
                </strong></span
              >
            </li>
          </ul>
          <p>
            <span style="font-family: 'times new roman', times; font-size: 12pt"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Interest Rate : 13 %</span
            >
          </p>
          <p>
            <span style="font-family: 'times new roman', times; font-size: 12pt"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Mode of charging interest : Quarterly</span
            >
          </p>
          <ul>
            <li>
              <span
                style="font-family: 'times new roman', times; color: #800080"
                ><strong
                  ><span style="color: #8e2b55">Sattlement Charge:&nbsp;</span>
                </strong></span
              >
            </li>
          </ul>
          <p>
            <span style="font-family: 'times new roman', times; font-size: 12pt"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              0.40 % on the value of traded securities.</span
            >
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
