<template>
  <v-card>
    <v-card-text>
      <div class="entry-content clearfix">
        <p>
          <span
            style="
              color: #8e2b55;
              font-family: times new roman, times;
              font-size: 14pt;
            "
            ><strong>Overview of Rupali Investment Limited</strong></span
          ><br />

          <br />
          <span style="font-family: times new roman, times; font-size: 12pt"
            >Rupali investment Limited (RIL) is a wholly owned subsidiary of
            Rupali Bank limited. RIL obtained license from the Bangladesh
            Securities and Exchange Commission (BSEC) to act as a full fledged
            Merchant Bank to carry out all the Merchant Banking operations under
            the BSEC (Merchant Banker and Portfolio Manager) regulations, 1996
            and started its journey on 09 August 2010. The Company has a
            separate Board of Directors consisting of 11 (Eleven) members. Other
            than Chief Executive Officer (CEO), all the Directors are
            non-executive and independent, 6 (six) of whom are nominated by RBL
            and the other six are nominated from the private sector by the
            holding company, Rupali Bank limited.</span
          >
        </p>
        <p>
          <span
            style="
              color: #8e2b55;
              font-family: times new roman, times;
              font-size: 14pt;
            "
            ><strong>Vision</strong></span
          >
        </p>
        <p>
          <span style="font-family: times new roman, times; font-size: 12pt"
            >To become a leading merchant bank in Bangladesh by providing&nbsp;
            superior and quality merchant banking services to our valuable
            investors.</span
          >
        </p>
        <p>
          <span
            style="
              color: #993366;
              font-family: times new roman, times;
              font-size: 14pt;
            "
            ><strong
              >&nbsp;<span style="color: #8e2b55">Mission</span></strong
            ></span
          >
        </p>
        <p>
          <span style="font-family: times new roman, times; font-size: 12pt"
            >Our mission is to</span
          >
        </p>
        <ul>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Develop sustainable partnership through delivering innovative
              solution to our clients immediate and long term needs.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Achieve the maximum economic value from the optimum resources
              utilization.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Create value through uncovering opportunity and success for our
              stakeholders.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Expand market horizon to achieve a sustainable growth.</span
            >
          </li>
        </ul>
        <p>
          <span
            style="
              color: #8e2b55;
              font-family: times new roman, times;
              font-size: 14pt;
            "
            ><strong>Strategic Objectives</strong></span
          >
        </p>
        <ul>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >To develop a customer oriented service culture with special
              emphasis on customer care and convenience.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >To lead the company to a new height by increasing the market
              share following a disciplined growth strategy.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >To leverage our technology platform and pen scalable systems to
              achieve cost-effective operations, efficient MIS, improved
              delivery capability and high service standards.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >To develop cost effective but innovative services that attracts
              our targeted customers and market segments.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >To maintain a high quality assets portfolio to achieve strong and
              sustainable returns to maximize shareholders’ value.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >To explore new avenues for growth and profitability</span
            >
          </li>
        </ul>
        <p>
          <span
            style="
              color: #8e2b55;
              font-family: times new roman, times;
              font-size: 14pt;
            "
            ><strong>Core Values</strong></span
          >
        </p>
        <ul>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              ><strong>Focus to the Customer- </strong>we value and care for our
              customer’s needs.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              ><strong>Integrity and trustworthiness</strong>– we uphold
              integrity and trustworthiness in our every business
              operations.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              ><strong>Transparency – </strong>we always maintain highest level
              of transparency to meet the expectations of our
              stakeholders.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              ><strong>Innovation </strong>– we promote creativity.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              ><strong>Sustainability – </strong>we do business to achieve for
              long term goal.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              ><strong>Community </strong>-We contribute to our society by being
              active, responsible and generous as a corporate citizen<em
                >.</em
              ></span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              ><strong>Team work </strong>-We work together to deliver excellent
              services to our stakeholders<em>. </em></span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              ><strong>Environment – </strong>we are devoted to create green
              environment on earth.</span
            >
          </li>
        </ul>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
