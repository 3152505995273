<template>
  <v-card>
    <v-card-text>
      <div class="entry-content clearfix">
        <p>
          <span
            style="
              color: #8e2b55;
              font-family: times new roman, times;
              font-size: 14pt;
            "
            ><strong>Functions:</strong></span
          >
        </p>
        <ul>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Underwriting to initial public offering of shares.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Underwriting of repeat public offering of shares.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Underwriting of right issue of shares.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Issue management;</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Managing Investors' Portfolios;</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Providing investment counsel to issuers and investors' including
              financial restructuring / engineering and corporate advisory
              services;</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Managing proprietary portfolio by purchasing shares and
              debentures including placement and equity participation;</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Dealing in other matters related to capital market
              operations.</span
            >
          </li>
        </ul>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
