<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(image, index) in images"
        :key="index"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card
          class="gallery-card"
          @click="openImageDialog(image)"
          elevation="2"
        >
          <v-img
            :src="image.downloadUrl"
            alt="Gallery Image"
            height="200px"
            class="gallery-image"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <v-card-title class="image-title">{{ image.Name }}</v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-img
          :src="selectedImage.downloadUrl"
          alt="Gallery Image"
          class="dialog-image"
        ></v-img>
        <v-card-title>{{ selectedImage.Name }}</v-card-title>
        <v-card-subtitle>{{ selectedImage.Date }}</v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase"; // Ensure you have your firebase configuration properly set up

export default {
  data() {
    return {
      images: [],
      dialog: false,
      selectedImage: {},
    };
  },
  methods: {
    openImageDialog(image) {
      this.selectedImage = image;
      this.dialog = true;
    },
    async fetchImages() {
      try {
        const snapshot = await db
          .collection("Gallery")
          .orderBy("Priority", "desc")
          .get();
        this.images = snapshot.docs.map((doc) => doc.data());
      } catch (error) {
        console.error("Error fetching images from Firestore:", error);
      }
    },
  },
  created() {
    this.fetchImages();
  },
};
</script>

<style scoped>
.gallery-card {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}
.gallery-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}
.gallery-image {
  border-radius: 8px;
}
.image-title {
  color: white;
  font-weight: bold;
  bottom: 16px;
  left: 16px;
  position: absolute;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.dialog-image {
  max-height: 500px;
  object-fit: cover;
}
</style>
