<template>
  <div class="entry-content clearfix">
    <v-card>
      <v-card-text>
        <p>
          <span style="font-size: 14pt; color: #8e2b55"
            ><strong
              ><span style="font-family: times new roman, times"
                >Business Principles</span
              >
            </strong></span
          >
        </p>
        <ul>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Developing and maintaining long-term relationship with clients
              giving priority to their need and interest and thus establishing a
              common goal.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Working together with the clients as a team to achieve best
              result.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Maintaining a positive moral support, a sense of community and
              ambitious approach to working.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >providing Effective services to clients based on quick and
              efficient decision making.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Keeping abreast of changing business practices and seeks to
              implement changes ahead of the competition.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Advising clients effectively and provide them with exceptional
              choices.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Providing clients with the best possible service in the
              industry.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Ensuring efficient decision making through powerful information
              systems and highly qualified personnel.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Building our clients’ businesses for the benefit of both parties
              through a strong and committed team of Company personnel and
              clients working in unison, focusing on the task at hand.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Placing great emphasis on retaining good employees and rewarding
              employees who perform most successfully providing the best
              services to valued clients.</span
            >
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
