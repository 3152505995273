<template>
  <v-container>
    <v-card>
      <v-card-text>
        <p>
          <span
            style="
              color: #8e2b55;
              font-size: 14pt;
              font-family: times new roman, times;
            "
            ><strong>Objectives:</strong></span
          >
        </p>
        <ul>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >To carry out merchant banking activities including issue
              management, underwriting and portfolio management with a view to
              develop a strong capital market;</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >To widen the scope and area of investment by mobilizing savings
              of small and medium level investors thereby generating demand for
              securities;</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >To achieve high quality in issue management and thereby
              facilitating the capital market with adequate supply of
              securities;</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >To infuse professionalism and efficiency in portfolio
              management;</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >To establish perfect combination of high quality purchase – sell
              execution capabilities and create a large network among investors
              and broker community.</span
            >
          </li>
        </ul>
        <p>
          <span
            style="
              color: #8e2b55;
              font-size: 14pt;
              font-family: times new roman, times;
            "
            ><strong
              >&nbsp;Merchant banking services to the Investors</strong
            ></span
          >
        </p>
        <ul>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Provide service with high degree of professionalism and using of
              modern technology.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Create long-term relationship based on mutual trust</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Respond to customer needs with speed and accuracy.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Share their values and beliefs.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Provide services at competitive pricing.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Provide fair treatment to all investors without any
              discrimination.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Deals with investors in a transparent manner and without any
              hidden cost.</span
            >
          </li>
          <p></p>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Maintain strict secrecy of investors account.</span
            >
          </li>
          <li>
            <span style="font-family: times new roman, times; font-size: 12pt"
              >Respond quickly to complaints received from the investors.</span
            >
          </li>
          <li>
            <span style="font-size: 12pt; font-family: times new roman, times"
              >Listen to clients and work for improvement of customer service as
              per their suggestion(s).</span
            >
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
