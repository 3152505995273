<template>
  <v-container class="recommendHomeVue">
    <v-card>
      <v-card-title>
        Notice
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        class="mytable"
        dense
      >
        <template v-slot:item.Name="{ item }">
          <v-avatar size="50" tile class="ma-3">
            <v-img :src="require('@/assets/pdficon.png')"></v-img>
          </v-avatar>
          <a>{{ item.Name }}</a>
        </template>
        <template v-slot:item.download="{ item }">
          <v-btn x-small color="primary" @click="downloadPdf(item.downloadUrl)">
            Download PDF
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import { ref, getDownloadURL } from "firebase/storage";
import { db, storage } from "@/firebase";
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Title", value: "Name" },
        { text: "Date", value: "Date", sortable: true, align: "start" },
        { text: "Download", value: "download" },
      ],
      desserts: [],
    };
  },
  created() {
    this.fetchNotices();
  },
  methods: {
    async fetchNotices() {
      db.collection("Notice").onSnapshot((querySnapshot) => {
        this.notices = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        this.desserts = this.notices.sort(
          (a, b) => new Date(b.Date) - new Date(a.Date)
        );
      });
    },
    downloadPdf(downloadUrl) {
      getDownloadURL(ref(storage, downloadUrl))
        .then((url) => {
          const a = document.createElement("a");
          a.href = url;
          a.download = "your-pdf-file.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.error("Error fetching PDF URL: ", error);
        });
    },
    downloadFile(downloadUrl) {
      const fileName = downloadUrl.substring(downloadUrl.lastIndexOf("/") + 1);
      axios({
        url: downloadUrl,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
};
</script>
<style>
/* Style the entire table */
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

/* Style the table header cells */
th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  text-align: left;
  padding: 10px;
  border-bottom: 2px solid #333;
}

/* Style the table data cells */
td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

/* Style alternate rows for better readability */
tr:nth-child(even) {
  background-color: #ffdede;
}
</style>
